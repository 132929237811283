import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import Cookies from 'js-cookie'
import { realms, roles } from '@/helpers/constants'
import getShortName from '@/helpers/shortName'
import getFullName from '@/helpers/fullName'

const state = () => ({
  accessToken: null,
  refreshToken: null,
  tokenExpiry: null,
  realm: null,
  data: null,
  profile_image: null,
  redirectUrl: null,
  activeGroup: null
})

export default {
  namespaced: true,
  state,
  getters: {
    isLoggedIn: (state) => !!state.accessToken,
    loggedInUserId: ({ data }) => data?.id || null,
    loggedInUserShortName (state) {
      const { firstName = '', lastName = '' } = state?.data || {}
      return getShortName(firstName, lastName)
    },
    loggedInUserFullName (state) {
      const { firstName, lastName } = state?.data || {}
      return getFullName(firstName, lastName)
    },
    hasPermission ({ data }) {
      return permission => {
        return (data?.roles || []).includes(permission)
      }
    },
    currentRole ({ data }) {
      let role
      if (data?.roles) {
        for (const i in roles) {
          for (const j in data.roles) {
            if (roles[i] === data.roles[j]) {
              role = data.roles[j]
              break
            }
          }
        }
      }
      return role
    },
    currentRoleKey ({ data }) {
      let role
      if (data?.roles) {
        const keys = Object.keys(roles)
        for (const i in keys) {
          if ((data?.roles || []).includes(roles[keys[i]])) {
            role = keys[i]
            break
          }
        }
      }
      return role
    },
    isFreelancer ({ realm }) {
      return realm === realms.freelancer
    },
    isAgency ({ realm }) {
      return realm === realms.agency
    },
    isManagingDirector ({ data }, getters) {
      if (!getters.isAgency) {
        return false
      }
      return (data?.roles || []).includes(roles.managingDirector)
    },
    isAccountManager ({ data }, getters) {
      if (!getters.isAgency) {
        return false
      }
      return (data?.roles || []).includes(roles.accountManager)
    },
    isAdmin ({ realm }) {
      return realm === realms.admin
    },
    isFinance ({ realm }) {
      return realm === realms.finance
    },
    groups ({ data }) {
      return data?.groups || []
    }
  },
  mutations: {
    resetStore ($state) {
      const { firstName = '', lastName = '' } = $state?.data || {}
      const realm = $state.realm
      const redirectUrl = $state.redirectUrl
      const data = { ...state(), realm, redirectUrl }
      if (realms.agency === realm) {
        data.data = { firstName, lastName }
      }
      Object.assign($state, data)
    },

    SET_REALM (state, realm) {
      state.realm = realm
    },

    SET_TOKENS (state, { accessToken, refreshToken, realm, exp }) {
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      state.realm = realm
      state.tokenExpiry = exp
    },

    UPDATE_AUTH_DATA (state, data) {
      state.data = { ...(state.data || {}), ...data }
    },

    SET_PROFILE_IMAGE (state, data) {
      state.profile_image = data
    },
    updateRedirectUrl (state, url) {
      state.redirectUrl = url
    },
    setActiveGroup (state, value) {
      state.activeGroup = value
    }
  },
  actions: {
    getKeycloakConfig ({ state }, type) {
      return new Promise((resolve) => {
        const realm = type.toUpperCase()
        resolve({
          realm: process.env[`VUE_APP_KEYCLOAK_${realm}_REALM`],
          clientId: process.env[`VUE_APP_KEYCLOAK_${realm}_CLIENT_ID`]
        })
      })
    },

    async freelancerLogin ({ commit, dispatch }, { form, type }) {
      const { realm, clientId } = await dispatch('getKeycloakConfig', type)
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('client_id', clientId)
        params.append('grant_type', 'password')
        params.append('scope', 'openid')
        params.append('username', form.email)
        params.append('password', form.password)

        const apiPath = `${process.env.VUE_APP_KEYCLOAK_AUTH_PATH.replace('{realm}', realm)}/token`

        axios.post(`${process.env.VUE_APP_KEYCLOAK_URL}${apiPath}`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            const { access_token: accessToken, refresh_token: refreshToken } = data
            dispatch('setTokens', { accessToken, refreshToken, remember: form.remember, realm: type })
            resolve()
          })
          .catch(() => {
            reject(new Error('Login failed'))
          })
      })
    },

    setTokens ({ commit }, { remember, accessToken, refreshToken, realm }) {
      const {
        email,
        roles,
        sub: id,
        preferred_username: username,
        resource_access: resourceAccess,
        exp,
        user_group: groups
      } = JSON.parse(atob(accessToken.split('.')[1]))

      commit('SET_TOKENS', { accessToken, refreshToken, exp, realm })

      commit('UPDATE_AUTH_DATA', {
        email,
        id,
        username,
        roles,
        resourceAccess,
        groups
      })

      const cookieOptions = {}

      if (remember) {
        cookieOptions.expires = 90
      }

      Cookies.set('remember', remember, { expires: 365 })
      Cookies.set('refreshToken', refreshToken, cookieOptions)
    },

    validateTokenExpiry ({ state, commit, dispatch }) {
      if (!state.accessToken) {
        return Promise.resolve()
      }

      const expDate = moment.unix(state.tokenExpiry)
      const now = moment()

      Vue.$log.info(`is token expired => ${!expDate.isAfter(now)}`)

      if (expDate.isAfter(now)) {
        return Promise.resolve()
      }

      return dispatch('updateToken')
    },

    async updateToken ({ state, commit, dispatch }) {
      commit('toggleTokenUpdateStatus', true, { root: true })
      const { realm, clientId } = await dispatch('getKeycloakConfig', state.realm)
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('client_id', clientId)
        params.append('grant_type', 'refresh_token')
        params.append('scope', 'openid')
        params.append('refresh_token', state.refreshToken)

        const apiPath = `${process.env.VUE_APP_KEYCLOAK_AUTH_PATH.replace('{realm}', realm)}/token`

        axios.post(`${process.env.VUE_APP_KEYCLOAK_URL}${apiPath}`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data, status }) => {
            const { access_token: accessToken, refresh_token: refreshToken } = data

            dispatch('setTokens', { accessToken, refreshToken, remember: Cookies.get('remember') === 'true', realm: state.realm })

            if (window.requests.length) {
              // accessToken has been refreshed to retry requests from all queues
              window.requests.forEach(cb => cb(accessToken))
              window.requests = []
            }
            resolve(accessToken)
            commit('toggleTokenUpdateStatus', false, { root: true })
          })
          .catch(() => {
            commit('resetStore')
            commit('toggleTokenUpdateStatus', false, { root: true })
            window.location.reload()
            reject(new Error('Session expired!'))
          })
      })
    },

    async validateToken ({ state, commit, dispatch }) {
      const { realm, clientId } = await dispatch('getKeycloakConfig', state.realm)
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('client_id', clientId)
        params.append('token', state.accessToken)

        const apiPath = `${process.env.VUE_APP_KEYCLOAK_AUTH_PATH.replace('{realm}', realm)}/token/introspect`

        axios.post(`${process.env.VUE_APP_KEYCLOAK_URL}${apiPath}`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data) {
              resolve()
            } else {
              reject(new Error('Invalid token'))
            }
          })
          .catch(() => {
            reject(new Error('Invalid token'))
          })
      })
    },

    async logout ({ state, commit, dispatch }) {
      const { realm, clientId } = await dispatch('getKeycloakConfig', state.realm)
      return new Promise((resolve, reject) => {
        const { accessToken, refreshToken } = state
        const params = new URLSearchParams()
        params.append('client_id', clientId)
        params.append('refresh_token', refreshToken)

        const apiPath = `${process.env.VUE_APP_KEYCLOAK_AUTH_PATH.replace('{realm}', realm)}/logout`

        axios.post(`${process.env.VUE_APP_KEYCLOAK_URL}${apiPath}`, params,
          {
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              common: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          })

        commit('resetStore')
        commit('chat/resetStore', null, { root: true })
        commit('agency/resetStore', null, { root: true })
        commit('freelancer/resetStore', null, { root: true })
        commit('agencies/resetStore', null, { root: true })
        commit('agenciesFilter/resetStore', null, { root: true })
        commit('creditNote/resetStore', null, { root: true })
        commit('creditNoteFilter/resetStore', null, { root: true })
        commit('freelancers/resetStore', null, { root: true })
        commit('freelancersFilter/resetStore', null, { root: true })
        commit('notification/resetStore', null, { root: true })
        commit('projects/resetStore', null, { root: true })
        commit('projectsFilter/resetStore', null, { root: true })
        commit('timeRecording/resetStore', null, { root: true })
        commit('timeRecordingFilter/resetStore', null, { root: true })
        commit('users/resetStore', null, { root: true })
        commit('usersFilter/resetStore', null, { root: true })

        Cookies.remove('refreshToken')

        resolve()
      })
    },

    async sendForgotPasswordEmail ({ commit }, { email, realm }) {
      const params = new FormData()
      params.append('email', email)
      params.append('realm', realm)
      return axios.post(`${process.env.VUE_APP_AGENCY_API_URL}forgot-password`, params)
    },

    setFreelancerPassword ({ commit, dispatch }, { token, password, agencyId }) {
      const params = new FormData()
      params.append('expiryToken', token)
      params.append('newPassword', password)
      params.append('agencyId', agencyId)

      return axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}create-password`, params,
        {
          headers: { 'content-type': 'application/x-www-form-urlencoded' }
        })
    },

    setUserPassword ({ commit, dispatch }, { token, password, firstname = '', lastname = '' }) {
      const params = new FormData()
      params.append('expiryToken', token)
      params.append('newPassword', password)
      params.append('firstname', firstname)
      params.append('lastname', lastname)

      return axios.post(`${process.env.VUE_APP_AGENCY_API_URL}create-user-password`, params,
        {
          headers: { 'content-type': 'application/x-www-form-urlencoded' }
        })
    },

    checkFreelancerRedirect ({ commit }, { token, email, agencyId }) {
      const params = new URLSearchParams()
      params.append('expiryToken', token)
      params.append('email', email)
      params.append('agencyId', agencyId)

      return axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}validate-freelancer-redirect?${params.toString()}`)
    },

    checkUserRedirect ({ commit }, { token, email }) {
      const params = new URLSearchParams()
      params.append('expiryToken', token)
      params.append('email', email)

      return axios.get(`${process.env.VUE_APP_AGENCY_API_URL}validate-user-redirect?${params.toString()}`)
    }
  }
}
